import React from "react";

const EncryptionIcon = () => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 100.000000 100.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,100.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          d='M425 841 c-71 -32 -105 -93 -105 -186 0 -30 -4 -55 -10 -55 -6 0 -19
-9 -30 -20 -18 -18 -20 -33 -20 -190 0 -227 -20 -210 240 -210 260 0 240 -17
240 210 0 157 -2 172 -20 190 -11 11 -24 20 -30 20 -5 0 -10 26 -10 58 -1 94
-37 155 -113 187 -48 20 -93 19 -142 -4z m163 -28 c51 -38 66 -69 70 -143 l4
-70 -161 0 -161 0 0 54 c0 87 30 143 95 172 40 19 119 12 153 -13z m120 -245
c17 -17 17 -339 0 -356 -17 -17 -399 -17 -416 0 -17 17 -17 339 0 356 17 17
399 17 416 0z'
        />
        <path
          d='M490 390 c0 -47 4 -80 10 -80 6 0 10 33 10 80 0 47 -4 80 -10 80 -6
0 -10 -33 -10 -80z'
        />
      </g>
    </svg>
  );
};

export default EncryptionIcon;
