import React from "react";
import fishbone from "./fishbone.png";

const Error404 = () => {
  return (
    <div className='container text-center p-0'>
      <div className='row align-items-center vh-100'>
        <h1 className='text-danger'>🥲 Resources not found</h1>
        <img src={fishbone} alt='piranhaprofits' />
        <a href='/'>Click here to back to the homepage</a>
      </div>
    </div>
  );
};

export default Error404;
