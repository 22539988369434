// output: 1,000.00
exports.formatPrice = (number) => {
  const format_number = number / 100;

  return format_number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

exports.acceptNumberOnly = (input) => {
  return input.replace(/[^0-9]/g, "");
};

// output: 1,000
exports.formatNumber = (number) => {
  const format_number = number;

  return format_number.toLocaleString();
};
