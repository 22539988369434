const { default: Notification } = require("./Notification");

exports.retrieveToken = () => {
  try {
    const token = localStorage.getItem("token");

    if (!token)
      throw new Notification({
        status: "fail",
        message: "Login is required to access the resources",
      });

    return { token: JSON.parse(token) };
  } catch (error) {
    return { error };
  }
};

// expected input = "oicwhoifhj9fpihf02hfpfjcw"
exports.setToken = (input_token) => {
  try {
    localStorage.setItem("token", JSON.parse(input_token));
  } catch (error) {
    return { error };
  }
};

exports.retrieveUser = () => {
  try {
    const user = localStorage.getItem("user");
    return { user: JSON.parse(user) };
  } catch (error) {
    return { error };
  }
};

// expected input = { email: "email", name: "name" }
exports.setUser = (input_user) => {
  try {
    localStorage.setItem("user", JSON.parse(input_user));
  } catch (error) {
    return { error };
  }
};

exports.updateLocalStorageObject = (storageKey, updateObject) => {
  // Step 1: Retrieve the object from local storage
  const storedData = JSON.parse(localStorage.getItem(storageKey));

  if (!storedData) return;

  // Step 2: Update the corresponding properties
  for (const key in updateObject) {
    if (updateObject.hasOwnProperty(key) && storedData.hasOwnProperty(key)) {
      storedData[key] = updateObject[key];
    }
  }

  // Step 3: Save the updated object back to local storage
  localStorage.setItem(storageKey, JSON.stringify(storedData));
};
