exports.createQueryString = (params) => {
  const queryString = Object.entries(params)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  return queryString ? `?${queryString}` : null;
};
