// ⭐️⭐️⭐️⭐️⭐️
// Notes:
// (1) Use for both tax inclusive and tax exclusive cases
// (2) Should be use to replace class Pricing(){}

export default class PricingCalculator {
  constructor({
    amount = 0,
    tax_rate = 0,
    percent_off = 0,
    amount_off = 0,
    discount_type = "percentage",
    is_tax_inclusive = false,
  }) {
    this._tax_rate = tax_rate;
    this._is_tax_inclusive = is_tax_inclusive;
    this._percent_off = percent_off;
    this._amount_off = amount_off;
    this._discount_type = discount_type;

    // mostly required values
    this._amount = amount;
    this._discount_amount = 0;
    this._tax_amount = 0;
    this._grand_total = 0;

    // optional
    this._discounted_price = 0;
  }

  // Notes: The most important function
  // Must initialise so that all the calculations below are correct
  _init() {
    if (this._is_tax_inclusive) {
      this._amount = this._amount / (1 + this._tax_rate / 100);
      return this;
    }
    // tax exclusive - aka original selling price
    this._amount = this._amount;
    return this;
  }

  _countDiscountAmount() {
    if (this._discount_type === "percentage") {
      this._discount_amount = (this._amount * this._percent_off) / 100;

      return this;
    }

    this._discount_amount = this._amount_off;
    return this;
  }

  _countTaxAmount() {
    const taxAmount =
      (this._amount - this._discount_amount) * (this._tax_rate / 100);
    this._tax_amount = taxAmount < 0 ? 0 : taxAmount; // in case discount amount is more than amount, it will show negative value

    return this;
  }

  // For discount amount > amount
  // Purposely choose to do this way instead of allowing negative value.
  // Can help especially helpful when there are more than one cart items where the discount of one of the items is wrongly set.
  // Example:
  // (1) Item A
  // -> Selling price: 15000
  // -> Discount amount: 2000
  // = grand_total = 13000

  // (2) Item B
  // -> Selling price: 15000
  // -> Discount amount: 20000
  // = grand_total = - 5000 <----- Logically, price should not be negative. Higher chance that this is a human error.

  // If we allow Item B to pass through, the grand_total would be equal to 8000.

  _countGrandTotal() {
    this._grand_total =
      this._discount_amount > this._amount
        ? "Discount amount should not be more than amount."
        : this._amount - this._discount_amount + this._tax_amount;
    return this;
  }

  // aka tax inclusive / exclusive price
  _countDiscountedPrice() {
    this._discounted_price = this._amount - this._discount_amount;
  }

  // Notes: Must be executed in sequence in order to ensure the calculations are correct
  calculate() {
    // Step 1: Initialise
    this._init();

    // Step 2: Get the discount amount
    this._countDiscountAmount();

    // Step 3: Get the tax amount
    this._countTaxAmount();

    // Step 4: Count the total amount
    this._countGrandTotal();

    // Optional
    this._countDiscountedPrice();

    // Convert the values at last so that all the decimal values can be used in the calculations
    return {
      amount: parseInt(Math.round(this._amount)),
      discount_amount: parseInt(Math.round(this._discount_amount)),
      discounted_price: parseInt(Math.round(this._discounted_price)),
      tax_amount: parseInt(Math.round(this._tax_amount)),
      grand_total: parseInt(Math.round(this._grand_total)),
    };
  }
}
