import Spinner from "react-bootstrap/Spinner";

// type = border / grow ...
// variant = dark / primary / secondary ...
function BasicSpinner({ type = "border", variant = "dark", small = false }) {
  return (
    <Spinner animation={type} variant={variant} size={small ? "sm" : ""} />
  );
}

export default BasicSpinner;
