import React from "react";
import "./logo.css";

export default function Logo() {
  return (
    <img
      className='img-fluid checkout-logo'
      src={`/pp_fish_image.webp`}
      alt='piranha profits logo'
    />
  );
}
