import React from "react";

const Homepage = () => {
  return (
    <div className='container text-center p-0'>
      <div className='row align-items-center vh-100'>
        <img src='/pp_fish_image.webp' alt='piranha profits logo' />
      </div>
    </div>
  );
};

export default Homepage;
