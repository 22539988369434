import React from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";

export default function CardElement({ onCardElementChange }) {
  const stripe = useStripe();
  const elements = useElements();

  const paymentElementOptions = {
    layout: "tabs",
    fields: {
      billingDetails: "never",
    },
  };

  const cardElementChangeHandler = () => {
    onCardElementChange({ stripe, elements });
  };

  return (
    <PaymentElement
      id='payment-element'
      onChange={cardElementChangeHandler}
      options={paymentElementOptions}
    />
  );
}
