import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Outlet, useNavigate } from "react-router-dom";
import { retrieveToken } from "../../utils/localStorageFn";

// Expected role in array: ["role1", "role2", "role3"...]
const RestrictedRoute = () => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    const { error: retrieveTokenrError, token: lsToken } = retrieveToken();

    if (retrieveTokenrError) {
      NotificationManager.error(retrieveTokenrError.message);
      return navigate("/login");
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/admins/me`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${lsToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const { error, payloads } = data;

        if (error) {
          const { message } = error;
          throw new Error(message);
        }

        localStorage.setItem("user", JSON.stringify(payloads));
        setPayload(payloads);
      })
      .catch((error) => {
        const jwtError = [
          "invalid token",
          "jwt malformed",
          "jwt signature is required",
          "invalid signature",
          "jwt audience invalid",
          "jwt issuer invalid",
          "jwt id invalid",
          "jwt subject invalid",
        ];
        const { message } = error;
        if (jwtError.includes(message)) {
          NotificationManager.error("Please login again...");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          return navigate("/login");
        }

        NotificationManager.error(message);
      });
  }, [navigate]);

  return payload ? <Outlet context={payload} /> : null;
};

export default RestrictedRoute;
