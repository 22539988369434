const { emailValidator } = require("./validationFn");

exports.extractEmailFromURL = () => {
  const currentURL = window.location.search;
  const queryParams = new URLSearchParams(currentURL);
  const email = queryParams.get("email");

  if (!emailValidator(email)) {
    return null;
  }

  return email;
};

exports.extractUTMParamsFromURL = () => {
  const currentURL = window.location.search;
  const queryParams = new URLSearchParams(currentURL);
  const params = {};

  let foundUTM = false;
  queryParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      params[key] = value;
      foundUTM = true;
    }
  });

  if (!foundUTM) {
    return null;
  }

  const query_string = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return { params, query_string };
};

exports.extractUTM = () => {
  const currentURL = window.location.search;
  const utm_source = new URLSearchParams(currentURL).get("utm_source");
  const utm_medium = new URLSearchParams(currentURL).get("utm_medium");
  const utm_campaign = new URLSearchParams(currentURL).get("utm_campaign");
  const utm_term = new URLSearchParams(currentURL).get("utm_term");
  const utm_content = new URLSearchParams(currentURL).get("utm_content");

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  };
};
