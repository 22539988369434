import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const cartSlice = createSlice({
  name: "cart",
  initialState: { items: [], grand_total: 0 },
  reducers: {
    clearCart(state) {
      state.items = [];
      state.grand_total = 0;
    },
    addItem(state, action) {
      const index = state.items.findIndex(
        (item) => item.price_id === action.payload.price_id
      );

      const { payload } = action;
      // item found in current cart
      // Action: Do nothing
      if (index >= 0)
        return NotificationManager.warning(
          `${action.payload.name} already in the cart.`
        );

      // item not found in current cart
      // Action: Add to cart
      state.items.push(payload);
      state.grand_total += action.payload.selling_price;

      NotificationManager.success(`${action.payload.name} added to cart.`);
    },
    removeItem(state, action) {
      state.items = state.items.filter((item) => {
        return item.price_id !== action.payload.price_id;
      });

      state.grand_total -= action.payload.selling_price;
    },
  },
});

export const { clearCart, addItem, removeItem } = cartSlice.actions;

export default cartSlice.reducer;
