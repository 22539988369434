// check whether it ends with .con
// mostly happen for PP cases based on historical data
exports.emailWithConPatternValidator = (txt) => {
  return /\.con(\.[a-zA-Z]{2,})?$/.test(txt);
};

// pattern with .con
// /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|((?!.*\.con$)([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// original regex
// works 99.9999999%
exports.emailValidator = (txt) => {
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailPattern.test(txt);
};
